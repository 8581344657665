import { resetPassword } from "features/auth/domain/authServices";
import { ErrorBox } from "features/common/ui/boxes/ErrorBox";
import { Layout } from "features/common/ui/layout/Layout";
import { LoadingIndicator } from "features/common/ui/LoadingIndicator";
import { PageIntro } from "features/common/ui/layout/PageIntro";
import { PasswordTextField } from "features/common/ui/forms/PasswordTextField";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MessageBox, MessageBoxType } from "features/common/ui/boxes/MessageBox";
import { IntroPosition, PageIntroItem } from "features/common/ui/layout/PageIntroItem";
import * as routes from "routes";
import { useValidatedFetch } from "features/common/hooks/UseValidatedFetch";
import { ResetPasswordResult } from "features/auth/domain/models";
import { FetchStatus } from "features/common/hooks/UseFetch";
import * as validation from "features/auth/domain/validation";
import { ValidationErrorBox } from "features/validation/ui/ValidationErrorBox";

const QUERY_PARAM_TOKEN = 'token';

export function ResetPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState('');


  const fetchResetPassword = useCallback(async () => {
    // Client side validate
    const validationResult = validation.validateResetPassword(password, t);
    if (!validationResult.isValid) {
      return { validationResult };
    }

    // Api call
    const token = searchParams.get(QUERY_PARAM_TOKEN)!;
    const result = await resetPassword(token, password);

    return { result };
  }, [password, searchParams, t]);

  const { executeFetch, fetchStatus, validationResult, fetchResult } = useValidatedFetch<ResetPasswordResult>(fetchResetPassword, false);

  useEffect(() => {
    if (!searchParams.has(QUERY_PARAM_TOKEN)) {
      navigate(routes.HOME);
    }
  }, [navigate, searchParams]);

  return <Layout>
    <PageIntro>
      <PageIntroItem position={ IntroPosition.left }>
        <h1 className="page-title">{ t('reset_password_page_title') }</h1>
      </PageIntroItem>
    </PageIntro>

    <div className="content-wrapper">
      <div className="inner-wrapper">
        { fetchStatus === FetchStatus.success && fetchResult?.success && <ResetPasswordSuccess /> }
        { fetchStatus === FetchStatus.success && !fetchResult?.success && <ResetPasswordError validationCodes={ fetchResult?.validationCodes || [] } /> }
        { fetchStatus === FetchStatus.unknownError && <ErrorBox errorMessage={ t('forgot_password_page_unknown_error') } /> }
        {
          validationResult
          && !validationResult.isValid
          && <ValidationErrorBox mainMessage={ t('user_validation_title') } messages={ validationResult.messages } />
        }
        {
          (fetchStatus === FetchStatus.idle || fetchStatus === FetchStatus.pending) &&
          <ResetPasswordForm isFetching={ fetchStatus === FetchStatus.pending } password={ password } onPasswordChanged={ setPassword } onSubmit={ executeFetch } />
        }
      </div>
    </div>
  </Layout>;
}


interface ResetPasswordFormProps {
  isFetching: boolean,
  password: string,
  onSubmit: () => void,
  onPasswordChanged: (password: string) => void,
}

function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { t } = useTranslation();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    props.onSubmit();
  };

  return <form className="form form--pasword-reset" onSubmit={ onSubmit }>
    <div className="form-row">
      <PasswordTextField identifier='password' label={ t('reset_password_page_password') } value={ props.password } disabled={ props.isFetching } onChange={ props.onPasswordChanged } />
    </div>
    <div className="form-row">
      <p>{ t('password_validation_requirements') }</p>
    </div>
    {
      props.isFetching
        ? <LoadingIndicator />
        : <div className="form-actions"><div className="form-action form-action--large"><button className="form-button" type="submit">{ t('reset_password_page_submit') }</button></div></div>
    }
  </form>;
}

function ResetPasswordSuccess() {
  const { t } = useTranslation();

  return <>
    <MessageBox type={ MessageBoxType.success } message={ t('reset_password_page_success') } />
    <Link to={ routes.LOGIN } className="btn-primary">{ t('reset_password_page_success_back') }</Link>
  </>;
}

interface ResetPasswordErrorProps {
  validationCodes: string[],
}
function ResetPasswordError({ validationCodes }: ResetPasswordErrorProps) {
  const { t } = useTranslation();

  if (validationCodes.includes('RESET_PASSWORD_TOKEN_INVALID')) {
    return (
      <>
        <ErrorBox errorMessage={ t('reset_password_page_invalid_token') } />
        <Link to={ routes.FORGOT_PASSWORD } className="btn-primary">{ t('reset_password_page_invalid_token_back') }</Link>
      </>
    );
  }

  return (
    <ErrorBox errorMessage={ t('reset_password_page_unknown_error') } />
  );
}
