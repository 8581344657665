import { ReactNode } from "react";
import styles from "./ErrorBox.module.scss";

interface ErrorBoxProps {
  errorMessage?: string,
  children?: ReactNode,
}

export function ErrorBox({ errorMessage, children }: ErrorBoxProps) {
  return <div className={ styles.errorBox }>
    <p>{ errorMessage }</p>
    { children }
  </div>;
}
